import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import Script from 'next/script';
import * as segmentSnippet from '@segment/snippet';

import { isInWhiteList } from './whitelist_clients';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const IS_ENABLED =
  (process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'staging') &&
  !!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export function renderSegmentScript() {
  if (!IS_ENABLED) return null;

  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: false,
  };

  return (
    <Script id="segment-script" dangerouslySetInnerHTML={{ __html: segmentSnippet.min(opts) }} />
  );
}

function generateFBC(fbclid) {
  if (fbclid) {
    const timeStamp = new Date().valueOf();
    return `fb.1.${timeStamp}.${fbclid}`;
  }

  return null;
}

export function SegmentProvider({ children }) {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    if (!IS_ENABLED) return;

    let url = `${pathname}`;
    if (searchParams.toString()) {
      url = `${pathname}?${searchParams}`;
    }
    const fingerprint = searchParams.get('fingerprint');
    const custom_campaign = searchParams.get('custom_campaign');
    const token = searchParams.get('token');
    const fbclid = searchParams.get('fbclid'); // facebook id param
    const gclid = searchParams.get('gclid'); // google id param
    let fbc = generateFBC(fbclid);
    const allowAds = isInWhiteList({ pathName: window?.location?.pathname }) ?? false;

    let page_params = {
      page: url,
      fbc,
      fbp: getCookie('_fbp'),
      gclid,
      client_allow_ads: allowAds,
      source: 'landing_pages',
    };

    //@TODO: add the campaign, decode the Param and send the solution
    const solution = ['thrive', 'bloom', 'move', 'academy', 'on-call'].find(
      (item) => url.includes(item) || null,
    );

    if (solution) {
      page_params.solution = solution;
    }

    global.analytics?.page({ ...page_params });

    let identifyProps;

    if (fingerprint) {
      identifyProps = { fingerprint };
    }

    if (custom_campaign) {
      identifyProps = { ...identifyProps, custom_campaign };
    }

    if (fbclid) {
      sessionStorage.setItem('fbclid', fbclid);
    }

    if (gclid) {
      sessionStorage.setItem('gclid', gclid);
    }

    // TODO: remove once Segment is working properly
    if (
      window?.lintrk &&
      (window.location.pathname.includes('/thrive') ||
        window.location.pathname.includes('/campaign')) &&
      allowAds
    ) {
      window.lintrk('track', { conversion_id: 18020380 });
    }

    if (token && pathname.substring('campaign/')) {
      const urlDecodedToken = decodeURIComponent(token);
      const decodedToken = Buffer.from(urlDecodedToken, 'base64').toString();
      const splitToken = decodedToken.split('|');
      const clientKey = splitToken[0];

      identifyProps = { ...identifyProps, clientKey };
    }

    const cluster_age = searchParams.get('cluster_a');
    if (cluster_age) {
      const urlDecodedToken = decodeURIComponent(cluster_age);
      const cluster_age_d = Buffer.from(urlDecodedToken, 'base64').toString();
      identifyProps = { ...identifyProps, cluster_age: cluster_age_d };
    }

    const cluster_gender = searchParams.get('cluster_g');
    if (cluster_gender) {
      const urlDecodedToken = decodeURIComponent(cluster_gender);
      const cluster_gender_d = Buffer.from(urlDecodedToken, 'base64').toString();
      identifyProps = { ...identifyProps, cluster_gender: cluster_gender_d };
    }

    const cluster_relationship = searchParams.get('cluster_r');
    if (cluster_relationship) {
      const urlDecodedToken = decodeURIComponent(cluster_relationship);
      const cluster_relationship_d = Buffer.from(urlDecodedToken, 'base64').toString();
      identifyProps = { ...identifyProps, cluster_relationship: cluster_relationship_d };
    }
    if (identifyProps) identify(fingerprint || null, identifyProps);
  }, [pathname, searchParams]);

  return <>{children}</>;
}

export function trackEvent(eventName, eventProps) {
  if (!IS_ENABLED) return null;

  const fbclid = sessionStorage.getItem('fbclid');
  const fbc = generateFBC(fbclid);
  const gclid = sessionStorage.getItem('gclid');
  const allowAds = isInWhiteList({ unit: 'thrive', pathName: window.location.pathname });

  window?.analytics?.track(eventName, {
    ...eventProps,
    fbc,
    fbp: getCookie('_fbp'),
    gclid,
    client_allow_ads: allowAds,
  });

  // TODO: remove once Segment is working properly
  if (
    window?.lintrk &&
    eventName === 'Button Clicked' &&
    eventProps.action === 'redirect_to_onboarding' &&
    (window.location.pathname.includes('/thrive') ||
      window.location.pathname.includes('/campaign')) &&
    allowAds
  ) {
    window.lintrk('track', { conversion_id: 17818804 });
  }
}

export function identify(id, identifyProps = {}) {
  if (!IS_ENABLED) return null;

  window?.analytics?.identify(id, identifyProps);
}
